// palette for WinBig21 Casino
$bg-color: #0d0e27;
$text-color: #e6e3c8;
$primary: #f8d07b;
$success: #2ECC71;
$info: #4682B4;
$danger: #cb2327;

//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;



.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;

    .casino-icon {
      path {
        fill: #fff !important;
      }
    }
  }
}
